<div fxLayout="row" fxLayoutGap="12px">
    <mat-card fxFlex="33">
        <mat-card-title align="center">
            <span class="md-headline">{{ 'Passwort ändern' | translate }}</span>
        </mat-card-title>
        <mat-card-content>
            <mw-form-builder #passwordForm
                             [mwFormModel]="formModel"
                             [mwFormData]="formData"
                             (mwFormSubmit)="updatePassword($event)">
            </mw-form-builder>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-raised-button
                    color="primary"
                    type="button"
                    (click)="passwordForm.submit()">
                <i class="fa fa-check"></i> {{'Speichern'|translate}}
            </button>
        </mat-card-actions>
    </mat-card>
    <mat-card fxFlex="33">
        <mat-card-title align="center">
            <span class="md-headline">{{ 'Zwei-Faktor Authentifizierung' | translate }}</span>
        </mat-card-title>
        <p class="clearfix"></p>
        <mat-card-content>
            <p>
                {{ 'Die Zwei-Faktor-Authentifizierung bietet dir, durch Eingabe eines einzigartigen Sicherheitscodes bei jeder Anmeldung, zusätzlichen Schutz für dein Konto.' | translate }}<br>
                {{ 'Dieser Code wird von einer Authentifizierungs-App generiert, die du ganz einfach auf deinem Mobilgerät installieren kannst. Passende Apps findest du im App Store deines Smartphones wenn du nach \"2FA\" suchst.' | translate }}</p>
            <p *ngIf="twoFactorAuthLogin == null">
                {{ 'Zur Aktivierung einfach auf den folgenden Button klicken und den QR-Code mithilfe einer Authentifizierungs-App deiner Wahl scannen und den dort generierten Code eingeben.' |translate }}
            </p>
            <p>
                {{ 'Weitere Informationen zur Zwei-Faktor-Authentifizierung findest du in der ' | translate}}
                <a helpLink="account.security.2fa" class="primary">{{ 'Hilfe' | translate }}</a>.
            </p>
            <p class="clearfix"></p>
        </mat-card-content>
        <mat-card-actions layout="row">
            <button *ngIf="twoFactorAuthLogin == null"
                    mat-raised-button
                    color="primary"
                    fxFlex="100"
                    type="button"
                    (click)="addOrRemoveTwoFactorAuthentication()">
                {{'Zwei-Faktor Authentifizierung aktivieren'|translate}}
            </button>
            <button *ngIf="twoFactorAuthLogin != null"
                    mat-raised-button
                    fxFlex="100"
                    type="button"
                    (click)="addOrRemoveTwoFactorAuthentication()">
                {{'Zwei-Faktor Authentifizierung deaktivieren'|translate}}
            </button>
        </mat-card-actions>
    </mat-card>
</div>