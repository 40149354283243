import {Component, Inject} from "@angular/core";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {OrderRepository} from "@app/app/order/data/repository/order.repository";
import {InconsistentVatDetailsCheckModeEnum} from "@app/app/common/model";

@Component({
    selector: "bb-inconsistent-vat-details-dialog",
    template: `
        <h2 mat-dialog-title>{{ 'Widersprüchliche Steuerinformationen' | translate }}</h2>
        <p>
            {{ 'Die eingegebenen Informationen zu der Besteuerung entsprechen nicht den von Billbee errechneten Werten.' | translate}}
            <br>
            {{ 'Bitte überprüfe die folgenden Werte.' | translate}}
        </p>
        <mat-dialog-content>

            <table class="material smaller">
                <thead>
                <tr>
                    <th>{{'Feld' | translate }}</th>
                    <th>{{'Eingegebener Wert' | translate }}</th>
                    <th>{{'Erwarteter Wert' | translate }}</th>
                    <th>{{'' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let kvp of data.changeSet | keyvalue">
                    <td>
                        {{ translations[kvp.key] || kvp.key }}
                    </td>
                    <td [ngStyle]="{textDecoration: result[kvp.key] ? 'line-through' : '', fontWeight: result[kvp.key] ? '' : 'bold'}">
                        <i *ngIf="kvp.value.client == '' || kvp.value.client == null; else clientValue">- leer -</i>
                        <ng-template #clientValue>{{ kvp.value.client }}</ng-template>
                    </td>
                    <td [ngStyle]="{fontWeight: result[kvp.key] ? 'bold' : ''}">
                        <i *ngIf="kvp.value.server == '' || kvp.value.server == null; else serverValue">- wird gelöscht -</i>
                        <ng-template #serverValue>{{ kvp.value.server }}</ng-template>
                    </td>
                    <td>
                        <mat-checkbox [checked]="result[kvp.key]" (change)="result[kvp.key] = $event.checked">
                            {{ 'Wert von Billbee übernehmen' | translate }}
                        </mat-checkbox>
                    </td>
                </tr>
                </tbody>
            </table>
        </mat-dialog-content>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field fxFlex="60">
                <mat-select *ngIf="data.isMasterUser" [(value)]="vatDetailsCheckMode">
                    <mat-option [value]="0"
                                selected>{{ 'Widersprüchliche Steuerinformationen immer anzeigen' | translate }}</mat-option>
                    <mat-option [value]="1">{{ 'Immer die eingegebenen Werte verwenden' | translate }}</mat-option>
                    <mat-option
                            [value]="2">{{ 'Immer die von Billbee errechneten Werte übernehmen' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-button [mat-dialog-close]="null">{{'Abbrechen'|translate}}</button>
                <button type="button" mat-button (click)="save()">{{ 'Speichern' | translate }}</button>
            </div>
        </mat-dialog-actions>
    `,
})
export class InconsistentVatDetailsDialogComponent {

    public translations = {
        "Vat1Rate": "USt. Satz nicht ermäßigt",
        "Vat2Rate": "USt. Satz ermäßigt",
        "MerchantVatId": "USt. Id / VAT Id (Verkäufer)",
        "CustomerVatId": "USt. Id / VAT Id (Kunde)",
    };

    public result: { [key: string]: boolean } = {};
    public vatDetailsCheckMode: InconsistentVatDetailsCheckModeEnum = InconsistentVatDetailsCheckModeEnum.ShowDialog;

    constructor(private readonly dialogRef: MatDialogRef<InconsistentVatDetailsDialogComponent>,
                private readonly orderRepository: OrderRepository,
                @Inject(MAT_DIALOG_DATA) public readonly data: {
                    changeSet: { [key: string]: { client: any, server: any } },
                    isMasterUser: boolean,
                },
    ) {
    }

    public save(): void {
        const newData = Object
            .keys(this.result)
            .filter((k) => this.result[k])
            .reduce((res, k) => ({
                ...res,
                [k]: this.data.changeSet[k].server,
            }), {});

        if (this.vatDetailsCheckMode !== InconsistentVatDetailsCheckModeEnum.ShowDialog) {
            this.orderRepository.setInconsistentVatCheckMode(this.vatDetailsCheckMode).then();
        }
        this.dialogRef.close(newData);
    }

}
