import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({name: "safeContent"})
export class SafeContentPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    public transform(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
@Pipe({name: "safeHtml"})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    public transform(url: any) {
        return this.sanitizer.bypassSecurityTrustHtml(url);
    }
}
