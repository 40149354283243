import {DatePipe, NgIf} from "@angular/common";
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyMenuModule} from "@angular/material/legacy-menu";
import {TranslateModule} from "@ngx-translate/core";
import {ShopViewModelDto} from "../../../../partners/shops/data/shop-view-model.dto";
import {ListItemCardComponent} from "../../../../shared/ui/list-item-card/list-item-card.component";
import {IconFromSyncStatePipe} from "../../container/shop-list/icon-from-sync-state.pipe";
import {StatusColorFromShopPipe} from "../../container/shop-list/status-color-from-shop.pipe";
import {ShopFromPartnerKeyPipe} from "../shopFromPartnerKey.pipe";

@Component({
    selector: "bb-shop-card",
    templateUrl: "./shop-card.component.html",
    styleUrls: ["./shop-card.component.scss"],
    standalone: true,
	imports: [
		NgIf,
        ListItemCardComponent,
        TranslateModule,
		MatLegacyMenuModule,
		MatDividerModule,
        MatIconModule,
        MatLegacyButtonModule,
        DatePipe,
		StatusColorFromShopPipe,
		ShopFromPartnerKeyPipe,
		IconFromSyncStatePipe,
    ],
})
export class ShopCardComponent {
	@Input({required: true}) shop!: ShopViewModelDto & {selected: boolean};
	@Input() loading = false;

	@Output() onClick = new EventEmitter<void>();
	@Output() onSelectionChange = new EventEmitter<void>();
	@Output() onClickEdit = new EventEmitter<void>();
	@Output() onClickReAuth = new EventEmitter<void>();
	@Output() onClickDelete = new EventEmitter<void>();
	@Output() onClickSwitchToManual = new EventEmitter<void>();
}
