import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {OkDialogComponent, SnackbarContainerComponent} from "@app/app/shared/presentation";
import * as coreSvc from "@bb-core/service";
import {take} from "rxjs/operators";
import {TranslationKey} from "../../core/entity";
import {TranslationService} from "./translation.service";

@Injectable({providedIn: "root"})
export class MessagingService implements coreSvc.MessagingService {
    private readonly snackBarDuration: number = 4000;

    constructor(
        private readonly matDialog: MatDialog,
        private readonly snackBar: MatSnackBar,
        private readonly translationService: TranslationService,
    ) {}

    public showSnackBar(
        message: TranslationKey,
        params?: {[p: string]: string | number | Date},
        duration = this.snackBarDuration,
    ): void {
        const translated = this.translationService.translate(message, params);
        const newMessage = MessagingService.normalizeMessage({message: translated, isBlocking: false});
        this.showSnackBarInternal(newMessage, duration);
    }

    public async showError(message: coreSvc.Message, error?: Error): Promise<void> {
        message = MessagingService.normalizeMessage(message);

        if (!message.isBlocking) {
            this.showSnackBarInternal(message);
        } else {
            await this.matDialog
                .open(OkDialogComponent, {
                    data: {
                        title: message.title,
                        message: message.message,
                        isError: true,
                    },
                })
                .afterClosed()
                .pipe(take(1))
                .toPromise();
        }
    }

    public async showMessage(message: coreSvc.Message): Promise<void> {
        message = MessagingService.normalizeMessage(message);
        if (!message.isBlocking) {
            this.showSnackBarInternal(message);
        } else {
            await this.matDialog
                .open(OkDialogComponent, {
                    data: {
                        title: message.title,
                        message: message.message,
                    },
                })
                .afterClosed()
                .pipe(take(1))
                .toPromise();
        }
    }

    private showSnackBarInternal(message: coreSvc.Message, duration = this.snackBarDuration): void {
        this.snackBar.openFromComponent(SnackbarContainerComponent, {
            data: message.message.replace(/<br>/g, "\n"),
            horizontalPosition: "end",
            verticalPosition: "top",
            duration,
        });
    }

    private static normalizeMessage(msg: coreSvc.Message): coreSvc.Message {
        let title = msg.title;
        let message = msg.message;
        if (message == null || message.trim() === "") {
            message = title;
            title = null;
        }

        if (!msg.isBlocking && title) {
            message = `${title}: ${message}`.trim();
            title = null;
        }
        return new coreSvc.Message({...msg, title, message});
    }
}
