import {Injectable, inject} from "@angular/core";
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {Observable, filter, switchMap} from "rxjs";
import {ShopsRepository} from "../../data/shops.repository";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {OauthShopAddCommand} from "../../service/command/oauth-shop-add.command";
import {VersacommerceShopNameDialogComponent} from "./versacommerce-shop-name-dialog/versacommerce-shop-name-dialog.component";

@Injectable({
    providedIn: "root",
})
export class VersaCommerceShop implements Shop {
    public readonly key = "versacommerce";
    public readonly name = "VersaCommerce";
    public readonly type = ShopType.Shop;
    public readonly logo = `assets/images/logo/shops/${this.key}.png`;

    private readonly dialog = inject(MatLegacyDialog);
    private readonly oauthAddCommand = inject(OauthShopAddCommand);
    private readonly repository = inject(ShopsRepository);

    add(): Observable<never> {
        return this.dialog
            .open(VersacommerceShopNameDialogComponent)
            .afterClosed()
            .pipe(
                filter((res) => !!res),
                switchMap((shopName) =>
                    this.oauthAddCommand.execute(this.repository.getAuthorizeUrl(this.key, {shopName}), this.key, this.logo),
                ),
            );
    }
}
