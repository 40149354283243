import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { CustomerExportSelection, CustomerExportSettings, FileFormat } from "@app/app/customer/core";
import { ExportCustomersViewModel } from "./export-customers.view-model";

@Component({
    selector: "bb-export-customers-dialog",
    template: `
        <h4 mat-dialog-title>{{ 'title.export_customers' | translate }}</h4>
        <mat-dialog-content>
            <p>
                <mw-form-builder #resultForm
                                 [mwFormModel]="viewModel.formModel"
                                 [mwFormData]="viewModel.exportSettings"
                                 (mwFormSubmit)="closeDialog($event)"
                >
                </mw-form-builder>
            </p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button type="button" mat-flat-button
                    [mat-dialog-close]="null">{{ 'button.cancel' | translate }}</button>
            <button type="button" mat-raised-button color="primary"
                    (click)="resultForm.submit()">{{ 'button.export' | translate }}</button>
        </mat-dialog-actions>
    `,
})
export class ExportCustomersComponent {
    public readonly viewModel: ExportCustomersViewModel = new ExportCustomersViewModel();

    constructor(private readonly dialogRef: MatDialogRef<ExportCustomersComponent>,
                @Inject(MAT_DIALOG_DATA) private readonly data: {
                    currentSettings: CustomerExportSettings,
                    fileFormats: FileFormat[],
                    showAppliedFilterOption: boolean,
                    showSelectedCustomersOption: boolean,
                }) {
        this.viewModel.exportSettings = data.currentSettings;
        this.viewModel.fileFormatField.reportChanges(data.fileFormats.map(f => ({text: f.Name, value: f.Value})));
        const selectionOptions = [{value: CustomerExportSelection.all, text: "label.all_customers"}];

        if (data.showSelectedCustomersOption) {
            selectionOptions.push({value: CustomerExportSelection.selected, text: "label.selected_customers"});
        }
        if (data.showAppliedFilterOption) {
            selectionOptions.push({value: CustomerExportSelection.filtered, text: "label.filtered_customers"});
        }
        this.viewModel.selectionField.reportChanges(selectionOptions);
    }

    public closeDialog(data: CustomerExportSettings): void {
        this.dialogRef.close(new CustomerExportSettings(data));
    }
}
