import {Injectable, inject} from "@angular/core";
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {EMPTY, Observable} from "rxjs";
import {Shop} from "../../model/shop";
import {ShopType} from "../../model/shop-type.enum";
import {EpagesHintDialogComponent} from "./epages-hint-dialog/epages-hint-dialog.component";

@Injectable({
    providedIn: "root",
})
export class EPagesShop implements Shop {
    public readonly key = "epagesrest";
    public readonly name = "ePages";
    public readonly type = ShopType.Shop;
    public readonly logo = `assets/images/logo/shops/${this.key}.svg`;

    private readonly dialogService = inject(MatLegacyDialog);

    add(): Observable<never> {
        this.dialogService.open(EpagesHintDialogComponent, {autoFocus: false});
        return EMPTY;
    }
}
