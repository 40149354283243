import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { BusyService } from "@app/app/common/service/busy.service";
import { SystemStatusService } from "@app/app/common/service/system-status";
import { TranslationKey } from "@bb-core/entity";
import { TranslationService } from "@bb-core/service";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs";
import { filter, map, shareReplay, take } from "rxjs/operators";
import { OnboardingAssistantDialogComponent } from "../../onboarding-assistant/container/onboarding-assistant-dialog/onboarding-assistant-dialog.component";
import { SignUpConfirmationDialogComponent } from "../../signed-out/sign-up/sign-up-confirmation-dialog/sign-up-confirmation-dialog.component";
import { selectAccount } from "../../states/selectors";

interface IMenuItem {
    tooltip: string;
    icon: string;
    iconSet?: string;
    text: string;
    link: string;
    children?: IMenuItem[];
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "bb-navigation",
    templateUrl: "./navigation.component.html",
    styles: [
        `
            .loading-indicator {
                position: absolute;
                top: 0;
                right: 0;
                width: 64px;
                color: #fff;
                height: 64px;
                z-index: 2;
                padding: 18px;
                text-align: center;
				border-left: 1px solid white;
            }

            main > div {
                height: 100%;
                overflow: auto;
            }
        `,
    ],
})
export class NavigationComponent implements OnInit {
    public readonly userName$ = this.store.select(selectAccount).pipe(map(({UserName}) => UserName));

    public userRoles: any = {};
    public menu: IMenuItem[] = [];
    public showSettingsMenu: boolean = false;

    public titleHistory: string[] = [];
    public urlHistory: object = {};

    public isDevEnvironment: boolean =
        !document.location.host.includes("app.billbee.") && !document.location.host.includes("sw.billbee.");
    public isImpersonated: boolean = false;
    public instanceName: string = "N/A";

    public settingsRoute: string;
    public showNavigation = new BehaviorSubject<boolean>(false);
    public contentPadding: boolean = true;

    private subscriptions: Subscription[] = [];

    constructor(
        private breakpointObserver: BreakpointObserver,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly title: Title,
        private readonly store: Store,
        @Inject("authService") public readonly authService: AuthService,
        @Inject("SettingsResource") private readonly settingsResource: ISettingsResource,
        @Inject("$state") private readonly $state: ng.ui.IStateService,
        public readonly systemStatusService: SystemStatusService,
        private readonly dialog: MatDialog,
        public readonly busyService: BusyService,
        translator: TranslationService,
    ) {
        this._setup().then((x) => x);
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route.snapshot),
                map((r: any) => {
                    const children: ActivatedRouteSnapshot[] = [];
                    while (r.firstChild) {
                        children.push(r.firstChild);
                        r = r.firstChild;
                    }
                    return children;
                }),
            )
            .subscribe((routes: ActivatedRouteSnapshot[]) => {
                const currentUrl: string[] = [];
                this.urlHistory = {};
                this.showNavigation.next(true);
                this.contentPadding = true;
                this.titleHistory = routes
                    .map((current) => {
                        this.settingsRoute = "settingsRoute" in current.data ? current.data.settingsRoute : "";
                        this.showNavigation.next(this.showNavigation.value === true &&
                            (current.data === null || current.data.showNavigation !== false));
                        this.contentPadding =
                            this.contentPadding === true &&
                            (current.data === null || current.data.contentPadding !== false);
                        if (current.url.length === 0 && routes.length > 1 && !current.data.showTitle) {
                            return null;
                        }
                        currentUrl.push(...current.url.map((c) => c.path));
                        if (!("title" in current.data) || ("hideTitle" in current.data && current.data.hideTitle)) {
                            return null;
                        }

                        let tmpTitle: string = current.routeConfig?.data?.title;
                        this.urlHistory[tmpTitle] = JSON.parse(JSON.stringify(currentUrl));
                        if (tmpTitle) {
                            Object.keys(current.params).forEach((k) => {
                                tmpTitle = tmpTitle.replace(`%${k}%`, current.params[k]);
                            });
                        }

                        if ("hideTitleInNav" in current.data && current.data.hideTitleInNav) {
                            tmpTitle = "!!" + tmpTitle;
                        }

                        return tmpTitle;
                    })
                    .filter((t) => t != null);

                this.titleHistory = this.titleHistory.map((x) => translator.translate(x as TranslationKey));

                this.title.setTitle(
                    [...this.titleHistory]
                        .map((e) => e.replace("!!", ""))
                        .reverse()
                        .slice(0, 2)
                        .reverse()
                        .join(" / ") + " :: Billbee",
                );
                this.titleHistory = this.titleHistory.filter((e) => e.indexOf("!!") !== 0);
                this.settingsActive = document.location.pathname.indexOf("/app_v2/settings") === 0;
            });
    }

    public ngOnInit(): void {
        combineLatest([
            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
            this.store.select(selectAccount).pipe(map(({ProfileId, IsMasterUser}) => ({ProfileId, IsMasterUser}))),
        ])
            .pipe(take(1))
            .subscribe(([_, user]) => {
                const url = new URL(document.location.href);
                if (sessionStorage.getItem("firstRun") === "true"
                    || (url.searchParams.has("firstRun") && url.searchParams.get("firstRun") === "true")) {
                    if (user.IsMasterUser) {
                        this.dialog.open(OnboardingAssistantDialogComponent, {
                            autoFocus: false,
                            panelClass: "no-padding",
                            width: "90vw",
                            maxWidth: "1200px",
                            height: "80vh",
                            maxHeight: "1000px",
                            disableClose: true,
                        });
                    } else {
                        this.dialog.open(SignUpConfirmationDialogComponent, {autoFocus: false});
                    }
                    sessionStorage.removeItem("firstRun");
                }
            });

        this.router.navigateByUrl(window.location.href.split("/app_v2").pop(), {replaceUrl: true});
    }

    private async _setup(): Promise<void> {
        const user = await this.authService.getPromise();
        if (!user) {
            return;
        }
        this.isImpersonated = this.authService.isImpersonated();
        this.instanceName = this.authService.getInstanceName();

        this.userRoles = user.roles ? user.roles.reduce((all, c) => ({...all, [c]: true}), {}) : {};
        this.username = user.userName;
        this.menu = [
            {
                children: [],
                icon: "fa-shopping-cart",
                link: "/order",
                text: "Bestellungen",
                tooltip: "Bestellungen",
            },
        ];

        if (
            this.userRoles["artikel - bearbeiten"] ||
            this.userRoles["artikel - exportieren"] ||
            this.userRoles["artikel - importieren"]
        ) {
            this.menu.push({
                children: [
                    {
                        icon: "fa-sitemap",
                        link: "/category/list",
                        text: "Kategorien",
                        tooltip: "Kategorien",
                    },
                    {
                        icon: "fa-coins",
                        link: "/article/pricegroups",
                        text: "Preisgruppen",
                        tooltip: "Preisgruppen",
                    },
                    this.authService.hasService("billbeecloud")
                        ? {
                              icon: "fa-images",
                              link: "/article/images",
                              text: "Bilder/Dateien",
                              tooltip: "Bilder/Dateien",
                          }
                        : null,
                    {
                        icon: "fa-info-circle",
                        link: "/article/custom-fields",
                        text: "Eigene Felder",
                        tooltip: "Eigene Felder",
                    },
                    {
                        icon: "fa-cloud-upload",
                        link: "/article/feed?FeedType=3",
                        text: "Importe",
                        tooltip: "Importe",
                    },
                ].filter((x) => x != null),
                icon: "fa-tags",
                link: "/article",
                text: "Artikel",
                tooltip: "Artikel",
            });
        }

        if (this.userRoles["kunden - liste anzeigen"]) {
            this.menu.push({
                children: [],
                icon: "fa-users",
                link: "/customer",
                text: "Kunden",
                tooltip: "Kunden",
            });
        }
        if (this.userRoles["zahlungsabgleich - ausführen"] || this.userRoles["zahlungseingänge"]) {
            let tmp: any = null;

            if (this.userRoles["zahlungsabgleich - ausführen"]) {
                tmp = {
                    children: [] as any[],
                    icon: "fa-credit-card",
                    link: "/payments",
                    text: "Zahlungsabgleich",
                    tooltip: "Zahlungsabgleich",
                };
            }

            if (this.userRoles["zahlungseingänge"]) {
                const incomes = {
                    children: [] as any[],
                    icon: "fa-university",
                    link: "/payments/incomes",
                    text: "Zahlungseingänge",
                    tooltip: "Zahlungseingänge",
                };

                if (tmp == null) {
                    tmp = incomes;
                } else {
                    tmp.children.push(incomes);
                }
            }

            this.menu.push(tmp);
        }

        if (this.userRoles["auftrag - versenden"]) {
            this.menu.push({
                children: [
                    {
                        icon: "fa-file-spreadsheet",
                        link: "/shipping/importshiplist",
                        text: "Versandliste einlesen",
                        tooltip: "Versandliste einlesen",
                    },
                    {
                        icon: "fa-money-check-edit",
                        link: "/shiplist",
                        text: "Versandlisten erstellen",
                        tooltip: "Versandlisten erstellen",
                    },
                    {
                        icon: "fa-dolly-flatbed",
                        link: "/shipping/shipments",
                        text: "Aktuelle Versandaufträge",
                        tooltip: "Aktuelle Versandaufträge",
                    },
                    {
                        icon: "fa-amazon",
                        iconSet: "fab",
                        link: "/shipping/feed?FeedType=1",
                        text: "Amazon Versandfeeds",
                        tooltip: "Amazon Versandfeeds",
                    },
                ],
                icon: "fa-truck",
                link: "/shipping",
                text: "Versand",
                tooltip: "Versand",
            });
        }

        let reportEntry = null;
        if (
            this.userRoles["berichte - umsatzberichte"] ||
            this.userRoles["berichte - mitarbeiter"] ||
            this.userRoles["berichte - pickliste"] ||
            this.userRoles["berichte - auftragsliste"]
        ) {
            this.menu.push(
                (reportEntry = {
                    icon: "fa-analytics",
                    link: "/report",
                    text: "Berichte",
                    tooltip: "Berichte",
                    children: [],
                }),
            );
        }

        if (this.userRoles["einstellungen - sonstiges"]) {
            (reportEntry != null ? reportEntry.children : this.menu).push(
                {
                    icon: "fa-calendar-exclamation",
                    link: "/report/events",
                    text: "System-Ereignisse",
                    tooltip: "System-Ereignisse",
                },
                {
                    icon: "fa-exchange",
                    link: "/report/currencyratios",
                    text: "Umrechnungskurse anzeigen",
                    tooltip: "Umrechnungskurse anzeigen",
                },
            );
        }

        if (
            this.userRoles["intern - 2fa reset"] ||
            this.userRoles["intern - accounting"] ||
            this.userRoles["intern - admin roles"] ||
            this.userRoles["intern - api keys"] ||
            this.userRoles["intern - basic"] ||
            this.userRoles["intern - credits"] ||
            this.userRoles["intern - feature roles"] ||
            this.userRoles["intern - finapi"] ||
            this.userRoles["intern - impersonate"] ||
            this.userRoles["intern - impersonate without pin"] ||
            this.userRoles["intern - shop actions"] ||
            this.userRoles["intern - user unlock wrong pwd"] ||
            this.userRoles["intern - dsgvo"]
        ) {
            this.menu.push({
                children: [
                    {
                        icon: "fa-exchange",
                        link: "/admin/currencyratios",
                        text: "Umrechnungskurse",
                        tooltip: "Umrechnungskurse",
                    },
                    this.userRoles["intern - dsgvo"]
                        ? {
                              icon: "fa-search",
                              link: "/admin/personal-data-search",
                              text: "PII Suche",
                              tooltip: "Suche nach Kontaktdaten in den Daten aller Kunden",
                          }
                        : null,
                    this.userRoles["intern - api keys"]
                        ? {
                              icon: "fa-sitemap",
                              link: "/admin/apikeys",
                              text: "API Keys",
                              tooltip: "API Keys",
                          }
                        : null,
                    this.userRoles["intern - finapi"]
                        ? {
                              icon: "fa-university",
                              link: "/admin/finapi",
                              text: "FinAPI Accounts",
                              tooltip: "FinAPI Accounts",
                          }
                        : null,
                    this.userRoles["intern - accounting"]
                        ? {
                              icon: "fa-file-export",
                              link: "/admin/accounting-export",
                              text: "Buchhaltungsexporte",
                              tooltip: "Buchhaltungsexporte",
                          }
                        : null,
                ].filter((x) => x != null),
                icon: "fa-user-crown",
                link: "/admin/user",
                text: "Admin",
                tooltip: "Admin",
            });
        }

        const settingsRoles = [
            "einstellungen - layouts",
            "einstellungen - mitarbeiter",
            "einstellungen - shops",
            "einstellungen - benutzerkonto",
            "einstellungen - sonstiges",
        ];

        this.showSettingsMenu = settingsRoles.find((r) => this.userRoles[r]) != null;
    }

    @Input()
    public appTitle: string = "";

    @Input()
    public username: string = "";

    @Input()
    public showNewButton: boolean = false;

    @Input()
    public filterOptions: Array<{val: any; text: any}> = [];
    @Output()
    public onClickNew: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public onFilterChange: EventEmitter<any> = new EventEmitter<any>();

    private ctrlPressed: boolean = false;

    @HostListener("keydown", ["$event.ctrlKey"])
    @HostListener("keyup", ["$event.ctrlKey"])
    private onCtrlChanged(pressed: boolean): void {
        this.ctrlPressed = pressed;
    }

    public expandedSidebar = false;

    public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map((result) => result.matches),
        shareReplay(),
    );

    public activeLink(item: IMenuItem): boolean {
        const pathname = document.location.pathname;
        const itemLink = item.link.split("?")[0];
        return pathname.indexOf(`/app_v2${itemLink}`) === 0 && !this.childActive(item);
    }

    public childActive(item: IMenuItem): boolean {
        return (
            item.children &&
            item.children.filter((child) => {
                const childLink = child.link.split("?")[0];
                return document.location.pathname.indexOf(`/app_v2${childLink}`) === 0;
            }).length > 0
        );
    }

    public orderAsIs = (a: any, b: any): number => 1;
    public settingsActive: boolean = false;

    public navigate(url: string): boolean {
        if (url.startsWith("http")) {
            window.open(url);
            return true;
        }

        if (this.ctrlPressed) {
            this.ctrlPressed = false;
            if (!url.startsWith("/app_v2") && !url.startsWith("/de")) {
                url = `/app_v2${url}`;
            }
            window.open(url);
        } else {
            if (url.startsWith("/de") || url.includes("ReturnToAdmin")) {
                document.location.href = url;
            } else {
                Promise.resolve(
                    (async () => {
                        const newUrl = url.replace("/app_v2", "");
                        let replaceUrl = false;
                        if (this.router.url === newUrl) {
                            replaceUrl = true;
                            await this.router.navigateByUrl(`#reload`, {replaceUrl, skipLocationChange: true});
                        }
                        this.router.navigateByUrl(newUrl, {replaceUrl});
                    })(),
                );
            }
        }
        return true;
    }

    public toggleSidebar() {
        this.expandedSidebar = !this.expandedSidebar;
    }

    public doSearch(str: string): void {
        this.router.navigateByUrl("/application/search?q=" + encodeURIComponent(str));
    }

    public openBillbeeStatus() {
        window.open("https://status.billbee.io", "_blank");
    }

    public openHelpScoutAnswers() {
        const funcOpen = new Function("Beacon('open')");
        const funcNavigate = new Function("Beacon('navigate', '/answers/')");
        funcOpen();
        funcNavigate();
    }

    public openHelpScoutSupport() {
        const funcOpen = new Function("Beacon('open')");
        const funcNavigate = new Function("Beacon('navigate', '/ask/message/')");
        funcOpen();
        funcNavigate();
    }

    public openChangelog() {
        const funcOpen = new Function("$sleek.open('changelog')");
        funcOpen();
	}
}
