import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ManualStockSyncDialogComponent} from "@app/app/article/dialog/manual-stock-sync-dialog.component";

@Injectable({providedIn: "root"})
export class ManualStocksyncService implements IManualStocksyncService {
    constructor(
        private readonly matDialog: MatDialog,
    ) {
    }

    public showManualStockSyncDialog(articleIds: number[]): Promise<boolean> {
        return new Promise(resolve => {
            const dialogRef = this.matDialog.open(ManualStockSyncDialogComponent, {
                data: {articleIds},
                width: "50%",
            });

            dialogRef.afterClosed().subscribe(result => {
                resolve(result ?? false);
            });
        });
    }
}
