import {Column, GridOptions} from "@app/app/grid/core";
import {ArchiveStateEnum, Customer, CustomerType} from "../../core";
import {TranslationService} from "@bb-core/service";

export class CustomerListViewModel {
    public totalCount: number = 0;

    public gridOptions: GridOptions;
    public languageColumn: Column<Customer>;
    public priceGroupColumn: Column<Customer>;

    public canEdit: boolean = false;
    public canCreate: boolean = false;
    public canDelete: boolean = false;
    public canImport: boolean = false;
    public canExport: boolean = false;
    public canMerge: boolean = false;
    public canList: boolean = false;
    public canCreateOrder: boolean = false;
    public dependenciesLoaded: boolean = false;

    constructor(translator: TranslationService) {
        this.gridOptions = new GridOptions<Customer>({
            SelectionMode: "multiple",
            PageSize: 10,
            ShowEditButton: true,
            HideWhenNoResults: false,
            Columns: [
                new Column({
                    Caption: "",
                    VisibleIndex: 0,
                    Name: "ArchiveState",
                    Type: "string",
                    AllowSorting: false,
                    AllowFiltering: false,
                    CellTemplateName: "htmlCellTemplate",
                    DisplayTransformer: (value) => {
                        if(value.ArchiveState == ArchiveStateEnum.RestoreRequested){
                            return "<i class=\"fal fa-fw fa-hourglass-half\"></i>";
                        }
                        if(value.ArchiveState == ArchiveStateEnum.Archived){
                            return "<i class=\"fal fa-fw fa-lock\"></i>";
                        }
                        return null;
                    },
                }),
                new Column({
                    Caption: "label.id",
                    VisibleIndex: 1,
                    Name: "Id",
                    Type: "number",
                }),
                new Column({
                    Caption: "label.number",
                    VisibleIndex: 2,
                    Name: "Number",
                    Type: "number",
                    AllowEditing: true,
                }),
                new Column({
                    Caption: "label.name",
                    VisibleIndex: 3,
                    Name: "Name",
                    Type: "string",
                    AllowEditing: true,
                }),
                new Column({
                    Caption: "label.primary_address",
                    VisibleIndex: 4,
                    Name: "PrimaryAddress",
                    Type: "string",
                    AllowSorting: false,
                    AllowFiltering: false,
                }),
                new Column({
                    Caption: "label.mail_addresses",
                    VisibleIndex: 5,
                    Name: "MailAddresses",
                    Type: "string",
                    AllowSorting: false,
                    AllowFiltering: false,
                    CellTemplateName: "htmlCellTemplate",
                    DisplayTransformer(value) {
                        const mails = value.MailAddresses?.map(m => m?.Value).filter(x => x != null && x.trim() !== "");
                        if (mails != null && mails.length === 1) {
                            return mails;
                        } else if (mails != null) {
                            return mails.join(", ");
                        }
                    },
                }),
                new Column({
                    Caption: "label.number_of_orders",
                    VisibleIndex: 6,
                    Name: "OrderCount",
                    Type: "number",
                    AllowSorting: false,
                    AllowFiltering: false,
                }),
                new Column({
                    Caption: "label.comments",
                    VisibleIndex: 7,
                    Name: "Comments",
                    Type: "string",
                    AllowSorting: false,
                    AllowFiltering: false,
                    CellTemplateName: "arrayCellTemplate",
                    DisplayTransformer(value) {
                        if (!value.Comments) {
                            return "";
                        }
                        return value.Comments.map(c => {
                            c.CreatedAt = new Date(c.CreatedAt);
                            const createdAtAsString = Intl.DateTimeFormat(navigator.language).format(c.CreatedAt);
                            return `${createdAtAsString} - ${c.CreatedBy} - ${c.Text}`;
                        });
                    },
                }),
                this.languageColumn = new Column({
                    Caption: "label.language",
                    VisibleIndex: 8,
                    Name: "LanguageId",
                    Type: "string",
                    AllowEditing: true,
                }),
                new Column({
                    Caption: "label.customer_type",
                    VisibleIndex: 9,
                    Name: "Type",
                    Type: "string",
                    AllowEditing: true,
                    LookupValues: [
                        {
                            value: CustomerType.Customer,
                            text: translator.translate("label.customer_type_customer"),
                        },
                        {
                            value: CustomerType.Business,
                            text: translator.translate("label.customer_type_business"),
                        },
                    ],
                }),
                this.priceGroupColumn = new Column({
                    Caption: "label.price_group",
                    VisibleIndex: 10,
                    Name: "PriceGroupId",
                    AllowEditing: true,
                    Type: "number",
                }),
                new Column({
                    Caption: "label.last_order",
                    VisibleIndex: 11,
                    Name: "LastOrder",
                    Type: "string",
                    AllowSorting: false,
                    AllowFiltering: false,
                    CellTemplateName: "htmlCellTemplate",
                    DisplayTransformer(value) {
                        if (value.LastOrder != null) {
                            const orderId = value.LastOrder?.Id;
                            const orderDate = new Date(value.LastOrder?.OrderDate);
                            const orderDateAsString = Intl.DateTimeFormat(navigator.language).format(orderDate);
                            return `<a href="#" onclick="window.ngRouter.navigateByUrl('/order?openOrder=${orderId}')">${orderId} (${orderDateAsString})</a>`;
                        }
                    },
                }),
                new Column({
                    Caption: "label.sources",
                    VisibleIndex: 12,
                    Name: "Sources",
                    Type: "string",
                    AllowSorting: false,
                    AllowFiltering: false,
                    CellTemplateName: "arrayCellTemplate",
                    DisplayTransformer(value) {
                        return value.Sources?.map(s => `${s.Source}: ${s.SourceId}`);
                    },
                }),
            ],
        });
    }
}
