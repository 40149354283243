import {CommonModule} from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
} from "@angular/core";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {MatLegacyMenuItem, MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";

@Component({
    selector: "bb-list-item-card",
    templateUrl: "./list-item-card.component.html",
    styleUrls: ["./list-item-card.component.scss"],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        MatCardModule,
        MatCheckboxModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
})
export class ListItemCardComponent implements OnInit, OnChanges {
    @Input() public title = "";
    @Input() public index: number | null = null;
    @Input() public imgSrc: null | string = null;
    @Input() public border: "success" | "error" | "warning" | "default" | null = "default";
    @Input() public checked: null | boolean = null;
    @Input() public loading = false;
    @Input() public disabled = false;

    @Output() public onClick = new EventEmitter<void>();
    @Output() public selectionChange = new EventEmitter<MatCheckboxChange>();

    @HostBinding("class.hasClickEvent") public hasClickEvent: boolean = false;
    @ContentChildren(MatLegacyMenuItem) public matMenuItems!: QueryList<MatLegacyMenuItem>;

    public ngOnInit(): void {
		this.setHasClickEvent();
	}
	
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.loading || changes.disabled) {
			this.setHasClickEvent();
		}
	}

	private setHasClickEvent(): void {
		this.hasClickEvent = this.onClick.observers.length > 0 && !this.disabled && !this.loading;
	}
}
