import {ChangeDetectionStrategy, Component} from "@angular/core";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyFormFieldModule} from "@angular/material/legacy-form-field";
import {TranslateModule} from "@ngx-translate/core";
import {BbInputComponent} from "../../../../../shared/ui/form/bb-input/bb-input.component";

@Component({
	templateUrl: "./versacommerce-shop-name-dialog.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatLegacyDialogModule,
        TranslateModule,
        BbInputComponent,
        MatLegacyButtonModule,
		MatLegacyFormFieldModule,
		ReactiveFormsModule,
    ],
})
export class VersacommerceShopNameDialogComponent {
	public readonly shopName = new FormControl("", Validators.required);
}
