import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { NumberField } from "@app/app/common/form/text-field/number-field.type";
import { TextField } from "@app/app/common/form/text-field/text-field.type";
import { Customer } from "@app/app/customer/core";
import { MergeCustomersViewModel } from "./merge-customers.view-model";

@Component({
    selector: "bb-merge-customers-dialog",
    template: `
        <h4 mat-dialog-title>{{ 'title.merge_customers' | translate }}</h4>
        <mat-dialog-content>
            <bb-meta-card icon="fa-info-circle">
                {{ 'text.merge_customers_description' | translate }}
            </bb-meta-card>
            <br>
            <div fxLayout="row" fxLayoutGap="12px" class="mat-padding">
                <mat-card [fxFlex]="25" fxLayout="column">
                    <h4 mat-card-title>{{ 'title.result' | translate}}</h4>
                    <mat-card-content>
                        <mat-form-field>
                            <mat-label>{{ 'label.target_customer' | translate }}</mat-label>
                            <mat-select (valueChange)="changeTargetCustomer($event)"
                                        [(ngModel)]="viewModel.selectedTargetCustomer">
                                <mat-option *ngFor="let customer of viewModel.customers; index as index"
                                            [value]="index">
                                    {{ customer.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mw-form-builder #mergeForm
                                         [mwFormModel]="viewModel.formModel"
                                         [mwFormData]="viewModel.targetCustomer"
                                         (mwFormSubmit)="closeDialog($event)"
                        >
                        </mw-form-builder>
                    </mat-card-content>
                </mat-card>

                <mat-card fxFlex style="overflow: auto">
                    <h4 mat-card-title>{{ 'title.customers_to_merge' | translate}}</h4>
                    <mat-card-content fxLayoutGap="12px" fxLayout="row">
                        <div fxLayout="column"
                             *ngFor="let customer of viewModel.customers; index as index">
                            <mw-form-builder #importForm
                                             [mwFormModel]="viewModel.readonlyFormModels[index]"
                                             [mwFormData]="customer"
                                             (mwFormSubmit)="closeDialog($event)"
                            >
                            </mw-form-builder>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button type="button" mat-flat-button
                    [mat-dialog-close]="null">{{ 'button.cancel' | translate }}</button>
            <button type="button" mat-raised-button color="primary"
                    (click)="mergeForm.submit()">{{ 'button.merge' | translate }}</button>
        </mat-dialog-actions>
    `,
})
export class MergeCustomersComponent {
    public readonly viewModel: MergeCustomersViewModel = new MergeCustomersViewModel();

    constructor(private readonly dialogRef: MatDialogRef<MergeCustomersComponent>,
                @Inject(MAT_DIALOG_DATA) private readonly data: { customersToMerge: Customer[] }) {
        this.viewModel.customers = data.customersToMerge;
        this.viewModel.targetCustomer = data.customersToMerge[0];
        this.viewModel.readonlyFormModels = data.customersToMerge.map(() => ({
            Name: new TextField({
                label: "label.name",
                required: false,
                readonly: true,
                disabled: true,
            }),
            Number: new NumberField({
                label: "label.customer_number",
                required: false,
                readonly: true,
                disabled: true,
            }),
        }));
    }

    public closeDialog(data: Customer): void {
        const targetCustomer = new Customer({...this.viewModel.targetCustomer, ...data});
        this.dialogRef.close(targetCustomer);
    }

    public changeTargetCustomer(index: number): void {
        this.viewModel.targetCustomer = this.viewModel.customers[index];
    }
}
