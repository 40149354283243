import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { OAuthService } from "@app/app/oauth/core";
import { MessagingService } from "@bb-core/service";
import { ChannelSettingsRepository } from "./data/channel-settings.repository";
import { ShopifyInitDialogComponent } from "./presentation/shopify-init-dialog/shopify-init-dialog.component";

@Injectable({providedIn: "root"})
export class ShopHelperService {
    constructor(
        private readonly dialog: MatDialog,
        private readonly channelSettingsRepository: ChannelSettingsRepository,
        private readonly messaging: MessagingService,
        private readonly oAuthService: OAuthService,
    ) {
    }

    public initAddShopify(): void {
        this.dialog.open(ShopifyInitDialogComponent, {autoFocus: false});
    }
}
