import {Component, Inject, Injectable, NgZone} from "@angular/core";
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from "@angular/material/legacy-snack-bar";
import {PDFHelperService} from "@app/app/common/service/pdf-helper.service";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "bb-snackbar",
    template: `{{ data.subject | async }}`,
})
export class SnackbarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public readonly data: {subject: BehaviorSubject<string>}) {}
}

@Injectable()
export class SignalrHandlerService {
    private snackBarSubject: BehaviorSubject<string> = null;
    private snackBarRef: MatSnackBarRef<any> = null;

    constructor(
        zone: NgZone,
        private readonly pdfHelper: PDFHelperService,
        private readonly matSnackbar: MatSnackBar,
        @Inject("SignalRService") private readonly sigR: SignalRService,
        @Inject("$alert") private readonly alertService: AlertService,
    ) {
        sigR.on(
            "notificationhub",
            "progress",
            (progressPercent: number, message: string, hasError: boolean = false) => {
                if (this.snackBarSubject == null || this.snackBarRef == null) {
                    this.snackBarSubject = new BehaviorSubject(message);
                    this.snackBarRef = matSnackbar.openFromComponent(SnackbarComponent, {
                        panelClass: "margin-top-nav-bar",
                        data: {
                            subject: this.snackBarSubject,
                        },
                        horizontalPosition: "end",
                        verticalPosition: "top",
                    });
                }
                this.snackBarSubject.next(message);
            },
        );
        sigR.on(
            "notificationhub",
            "FileIsReadyForDownload",
            (withError: boolean, message: string, downloadUrl: string, hasFileName: boolean) => {
                if (this.snackBarSubject != null) {
                    this.snackBarSubject.next(message);
                }

                if (withError) {
                    this.alertService.failure("Es ist ein Fehler aufgetreten", message);
                } else if (message) {
                    this.alertService.success(message);
                }

                this._hideProgress();

                if (downloadUrl) {
                    if (hasFileName) {
                        zone.runOutsideAngular(() => {
                            // Workaround mit dem Umbau der Order List auf Angular entfernen!
                            const iframe = document.createElement("iframe");
                            iframe.width = "0";
                            iframe.height = "0";
                            iframe.style.border = "none";
                            iframe.style.display = "none";
                            iframe.style.width = "0px";
                            iframe.style.height = "0px";
                            document.body.appendChild(iframe);
                            iframe.src = downloadUrl;
                            window.setTimeout(() => {
                                iframe.parentElement.removeChild(iframe);
                            }, 2000);
                        });
                        // pdfHelper.DownloadFileAsBlob(downloadUrl);
                    } else {
                        window.location.href = downloadUrl;
                    }
                }
            },
        );

        sigR.on("notificationhub", "HideProgress", () => this._hideProgress());
    }

    private _hideProgress(): void {
        setTimeout(() => {
            if (this.snackBarRef != null) {
                this.snackBarRef.dismiss();
                this.snackBarRef = null;
            }
            if (this.snackBarSubject != null) {
                this.snackBarSubject.complete();
                this.snackBarSubject = null;
            }
        }, 2000);
    }
}
