import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {OnboardingAssistantFinishTaskComponent} from "../tasks/finish/finish.component";
import {ONBOARDING_ASSISTANT_STEPS} from "./steps.token";

const STEP_TASK_COMPONENTS = [
	OnboardingAssistantFinishTaskComponent,
];

@NgModule({
    imports: [
		CommonModule,
		MatButtonModule,
		TranslateModule,
		MatDialogModule,
		RouterModule,
    ],
    declarations: STEP_TASK_COMPONENTS,
    providers: [
        {
            provide: ONBOARDING_ASSISTANT_STEPS,
            useValue: {
                title: "title.finish",
                subtitle: "text.finish_description",
                tasks: STEP_TASK_COMPONENTS,
            },
            multi: true,
        },
    ],
})
export class OnboardingAssistantFinishStepModule {}
