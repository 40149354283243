<mat-form-field [matTooltip]="tooltip" *ngIf="type !== 'textarea'; else textarea">
    <mat-label>{{ label }}</mat-label>
    <input matInput type="text" [formControl]="control" [required]="required">
    <mat-hint *ngIf="hint" [innerHtml]="hint"></mat-hint>
    <mat-error *ngFor="let error of control.errors | bbErrors: customErrors">{{ error }}</mat-error>
</mat-form-field>

<ng-template #textarea>
    <mat-form-field [matTooltip]="tooltip">
        <mat-label>{{ label }}</mat-label>
        <textarea matInput [type]="type" [formControl]="control"  [required]="required"></textarea>
        <mat-hint *ngIf="hint" [innerHtml]="hint"></mat-hint>
        <mat-error *ngFor="let error of control.errors | bbErrors: customErrors">{{ error }}</mat-error>
    </mat-form-field>
</ng-template>