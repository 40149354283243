import {animate, state, style, transition, trigger} from "@angular/animations";
import {ChangeDetectionStrategy, Component, Inject} from "@angular/core";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {merge, of} from "rxjs";
import {delay, startWith, switchMap} from "rxjs/operators";
import {OnboardingAssistantStep} from "../../model/step";
import {ONBOARDING_ASSISTANT_STEPS} from "../../steps/steps.token";
import {OnboardingAssistantComponentStore} from "./onboarding-assistant.store";
declare const analytics: any;

@Component({
    selector: "app-onboarding-assistant",
    templateUrl: "./onboarding-assistant-dialog.component.html",
    styleUrls: ["./onboarding-assistant-dialog.component.scss"],
    providers: [OnboardingAssistantComponentStore],
    animations: [
        trigger("openClose", [
            state("open", style({opacity: 1})),
            state("closed", style({opacity: 0, transform: "translateY(-20px)"})),
            transition("open => closed", [animate("0.3s ease-in-out")]),
            transition("closed => open", [animate("0.3s ease-in-out")]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantDialogComponent {
    public readonly activeStep$ = this.onboardingStore.activeStepIndex$;
    public readonly taskComponent$ = this.onboardingStore.taskComponent$.pipe(delay(300));
    public readonly hasBackButton$ = this.onboardingStore.hasBackButton$;
    public readonly progress$ = this.onboardingStore.progress$;

	public readonly openClose$ = this.onboardingStore.taskComponent$.pipe(
		startWith("open"),
		switchMap(() => merge(of("closed"), of("open").pipe(delay(300)))),
	);

    constructor(
        @Inject(ONBOARDING_ASSISTANT_STEPS) public readonly steps: OnboardingAssistantStep[],
        private readonly onboardingStore: OnboardingAssistantComponentStore,
        private readonly ref: MatDialogRef<OnboardingAssistantDialogComponent>,
    ) {}

    onClickPrev() {
        this.onboardingStore.previousTask();
    }

    closeAssistant() {
        analytics.track("OnboardingAssistentSkipped");
        this.ref.close();
    }
}
