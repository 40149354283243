import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {TranslateModule} from "@ngx-translate/core";
import {BbInputComponent} from "../../shared/ui/form/bb-input/bb-input.component";
import {BbSelectComponent} from "../../shared/ui/form/bb-select/bb-select.component";
import {OnboardingAssistantAddChannelsTaskComponent} from "../tasks/add-channels/add-channels.component";
import {OnboardingAssistantSelectChannelTaskComponent} from "../tasks/select-channel/select-channel.component";
import {ONBOARDING_ASSISTANT_STEPS} from "./steps.token";

const STEP_TASK_COMPONENTS = [
	OnboardingAssistantAddChannelsTaskComponent,
	OnboardingAssistantSelectChannelTaskComponent,
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        BbInputComponent,
        MatButtonModule,
        BbSelectComponent,
        ReactiveFormsModule,
		MatIconModule,
		MatTooltipModule,
    ],
    declarations: STEP_TASK_COMPONENTS,
    providers: [
        {
            provide: ONBOARDING_ASSISTANT_STEPS,
            useValue: {
                title: "title.channel_connections",
                subtitle: "text.channel_connections_description",
                tasks: STEP_TASK_COMPONENTS,
            },
            multi: true,
        },
    ],
})
export class OnboardingAssistantAddChannelsStepModule {}
