import {Component, Inject} from "@angular/core";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {AccountService} from "@app/app/common/service/da/account.service";
import {InitializeTwoFactorAuthentication} from "@app/app/common/model/account/initialize-two-factor-authentication";
import {TextField} from "@app/app/common/form/types";
import {FormModel} from "@mintware-de/form-builder";

@Component({
    selector: "bb-add-2fa-dialog",
    template: `
        <h3 mat-dialog-title>{{ 'Zwei-Faktor Bestätigung' | translate }}</h3>
        <ng-container *ngIf="initModel; else loadingTemplate">
            <mat-dialog-content>
                <p>{{ 'Scanne den folgenden QR-Code mit deiner Authentifizierungs App.' | translate }}<br>
                    {{ '(z.B. Google Authenticator)' | translate}}</p>
                <div class="text-center">
                    <img [src]="initModel.QrCodeDataUrl" alt="2FA QR Code" style="max-width: 256px; width: 100%">
                </div>
                <mw-form-builder #form
                                 [mwFormModel]="formModel"
                                 [mwFormData]="formData"
                                 (mwFormSubmit)="submitForm($event)"
                ></mw-form-builder>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
                <button mat-button mat-dialog-close>{{ 'Abbrechen' | translate }}</button>
                <button mat-button (click)="form.submit()">
                    {{ 'Verbinden' | translate }}
                </button>
            </mat-dialog-actions>
        </ng-container>
        <ng-template #loadingTemplate>
            {{ 'Daten werden geladen...' | translate }}
        </ng-template>
    `,
})
export class Add2FaDialogComponent {
    public initModel: InitializeTwoFactorAuthentication = null;

    public formData = {Otp: ""};

    public formModel: FormModel = {
        Otp: new TextField({
            label: "Code",
        }),
    };

    public constructor(private readonly dialogRef: MatDialogRef<Add2FaDialogComponent>,
                       private readonly accountService: AccountService,
                       @Inject("$alert") private readonly alertService: AlertService,
    ) {
        this._fetchDetails();
    }

    private async _fetchDetails(): Promise<void> {
        this.initModel = await this.accountService.getInitial2FAQrCodeUrl();
    }

    public async submitForm($event: any): Promise<void> {
        try {
            await this.accountService.activateTwoFactorAuthentication({
                Otp: $event.Otp,
                Period: this.initModel.Period,
                SecretKey: this.initModel.SecretKey,
            });
            this.alertService.success("2FA wurde erfolgreich eingerichtet.");
            this.dialogRef.close(true);
        } catch (e) {
            this.alertService.failureFromHttpResult(e, "Es ist ein Fehler aufgetreten", true);
        }

    }
}
