import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { TranslationKey } from "@bb-core/entity";

@Component({
    selector: "bb-confirm-dialog",
    template: `
        <h4 mat-dialog-title>{{ title }}</h4>
        <mat-dialog-content [innerHTML]="message">
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button [matDialogClose]="false">{{ noText | translate }}</button>
            <button *ngIf="!isDangerous; else dangerousButton"
                    [matDialogClose]="true"
                    mat-button>
                {{ yesText | translate }}
            </button>
            <ng-template #dangerousButton>
                <button mat-raised-button color="warn" [matDialogClose]="true">{{ yesText | translate }}</button>
            </ng-template>
        </mat-dialog-actions>
    `,
})
export class ConfirmDialogComponent {
    public isDangerous: boolean = false;
    public title: string = "";
    public message: string = "";
    public yesText: TranslationKey = "button.yes";
    public noText: TranslationKey = "button.no";

    constructor(@Inject(MAT_DIALOG_DATA) data: {
        title: string,
        message: string,
        options:
            {
                yesText?: TranslationKey,
                noText?: TranslationKey,
                isDangerousToProceed?: boolean,
            }
    }) {
        this.title = data.title;
        this.message = data.message;
        this.yesText = data.options?.yesText ?? "button.yes";
        this.noText = data.options?.noText ?? "button.no";
        this.isDangerous = data.options?.isDangerousToProceed ?? false;
    }
}
