import {AsyncPipe, JsonPipe, NgFor, NgIf} from "@angular/common";
import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyDialogModule, MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {TranslateModule} from "@ngx-translate/core";
import {map, startWith} from "rxjs/operators";
import {Shop} from "../../../../partners/shops/model/shop";
import {ShopType} from "../../../../partners/shops/model/shop-type.enum";
import {SHOPS} from "../../../../partners/shops/shops.token";
import {BbInputComponent} from "../../../../shared/ui/form/bb-input/bb-input.component";
import {ListItemCardComponent} from "../../../../shared/ui/list-item-card/list-item-card.component";
import {BbTextDividerComponent} from "../../../../ui/bb-text-divider/bb-text-divider.component";
import {SelectShopCardComponent} from "../select-shop-card/select-shop-card.component";

@Component({
    templateUrl: "./select-new-shop-dialog.component.html",
    styleUrls: ["./select-new-shop-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatLegacyDialogModule,
        TranslateModule,
        MatLegacyButtonModule,
        ReactiveFormsModule,
		BbInputComponent,
		NgIf,
        NgFor,
        AsyncPipe,
        JsonPipe,
        BbTextDividerComponent,
        ListItemCardComponent,
        SelectShopCardComponent,
    ],
})
export class SelectNewShopDialogComponent {
    private readonly shops = inject(SHOPS);
    private readonly dialogRef = inject(MatLegacyDialogRef);
	
	public readonly ShopType = ShopType;
    public readonly search = new FormControl("");
    public readonly filteredShops$ = this.search.valueChanges.pipe(
        startWith(""),
        map((filter) => this.shops.filter(({name}) => name.toLowerCase().includes(filter?.toLowerCase() || ""))),
        map((shops) => ({
            featured: shops.filter((shop) => shop.featured),
            others: shops.filter((shop) => !shop.featured),
        })),
    );

    public clickShop(shop: Shop): void {
        this.dialogRef.close(shop);
    }
}
