import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {Observable} from "rxjs";
import {EnterResetAccountSettingsDialogComponent} from "./presentation/enter-reset-account-settings-dialog/enter-reset-account-settings-dialog.component";
import {EnterAccountSyncSettingsDialogComponent} from "./presentation/enter-account-sync-settings-dialog/enter-account-sync-settings-dialog.component";
import {AccountSyncSettings} from "./model/account-sync-settings.model";
import {AccountResetSettingsDto} from "./data/account-reset-settings.dto";

@Injectable({
	providedIn: "root",
})
export class AccountService {
	constructor(private readonly dialog: MatDialog) {
	}

	public openEnterAccountResetSettingsDialog(): Observable<AccountResetSettingsDto | undefined> {
		return this.dialog
            .open(EnterResetAccountSettingsDialogComponent)
            .afterClosed();
	}

	public openEnterAccountSyncSettingsDialog(): Observable<AccountSyncSettings | undefined> {
		return this.dialog
            .open(EnterAccountSyncSettingsDialogComponent)
            .afterClosed();
	}
}
