import { Component, Inject, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { CustomerAddress } from "@app/app/customer/core";
import { FormBuilderComponent } from "@mintware-de/form-builder";
import { CustomerAddressMergeFormModelHelper } from "./customer-address-merge.form-model";
import { MergeCustomerAddressesViewModel } from "./merge-customer-addresses.view-model";

@Component({
    selector: "bb-merge-customer-addresses-dialog",
    template: `
        <h4 mat-dialog-title>{{ 'title.merge_addresses' | translate }}</h4>
        <mat-dialog-content>
            <div fxLayout="row" fxLayoutGap="12px" class="mat-padding">
                <mat-card>
                    <h4 mat-card-title>{{ 'title.target_address' | translate}}</h4>
                    <mat-card-content>
                        <mw-form-builder #resultForm
                                         [mwFormData]="viewModel.newAddress"
                                         [mwFormModel]="viewModel.newAddressFormModel"
                                         (mwFormSubmit)="submit($event)"
                        >
                        </mw-form-builder>
                    </mat-card-content>
                </mat-card>
                <mat-card fxFlex style="overflow: auto" *ngIf="viewModel.mergeAddressFormModel">
                    <h4 mat-card-title>{{ 'title.addresses_to_merge' | translate }}</h4>
                    <mat-card-content fxLayout="row" fxLayoutGap="12px" fxAlign="start stretch">
                        <div *ngFor="let address of viewModel.addresses; index as index"
                             class="address-to-merge-container">
                            <mw-form-builder #sourceAddressForm
                                             [mwFormData]="address"
                                             [mwFormModel]="viewModel.mergeAddressFormModel[index].formModel"
                            >
                            </mw-form-builder>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button type="button" mat-flat-button
                    [mat-dialog-close]="null">{{ 'button.cancel' | translate }}</button>
            <button type="button" mat-raised-button color="primary"
                    (click)="resultForm.submit()">{{ 'button.save' | translate }}</button>
        </mat-dialog-actions>
    `,
    styles: [`.address-to-merge-container {
        margin-right: 12px;
        border-right: 1px solid #eee;
        padding-right: 4px;
    }`],
})
export class MergeCustomerAddressesComponent {
    public readonly viewModel: MergeCustomerAddressesViewModel = new MergeCustomerAddressesViewModel();

    @ViewChild("resultForm", {static: false})
    public readonly form: FormBuilderComponent;

    @ViewChildren("sourceAddressForm")
    public readonly sourceAddressForms: QueryList<FormBuilderComponent>;

    constructor(@Inject(MAT_DIALOG_DATA) data: { addressesToMerge: CustomerAddress[] },
                private readonly dialog: MatDialogRef<MergeCustomerAddressesComponent>) {
        this.viewModel.addresses = data.addressesToMerge;
        for (let i = 0; i < data.addressesToMerge.length; i++) {
            this.viewModel.mergeAddressFormModel[i] = new CustomerAddressMergeFormModelHelper(i == 0 ? "primary" : null, (path) => this.useValue(path, i));
        }
        this.viewModel.newAddress = this.viewModel.addresses[0];
    }

    public useValue(path: string, sourceFormIndex: number): void {
        const sourceFormComponent = this.sourceAddressForms.get(sourceFormIndex);
        const value = sourceFormComponent.group.get(path).value;
        for (let i = 0; i < this.sourceAddressForms.length; i++) {
            this.viewModel.mergeAddressFormModel[i].buttons[path].options.color = i === sourceFormIndex ? "primary" : null;
        }
        this.form.group.get(path).patchValue(value);
    }

    public submit(address: CustomerAddress): void {
        address.AddressType = this.viewModel.addresses[0].AddressType;
        address.Id = this.viewModel.addresses.find(x => x.Id != null && x.Id !== 0)?.Id ?? 0;
        this.dialog.close(new CustomerAddress(address));
    }
}
