import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import {
    Customer,
    CustomerAddress,
    CustomerExportSettings,
    CustomerImportSettings,
    CustomerInteractionService,
    CustomerMetaInformation,
    CustomerSource,
    FileFormat,
    ImportedCustomer,
} from "@app/app/customer/core";
import {
    EditCustomerAddressComponent,
    EditMetaDataComponent,
    ExportCustomersComponent,
    ImportCustomersComponent,
    ImportResultComponent,
    MergeCustomerAddressesComponent,
    MergeCustomersComponent,
} from "@app/app/customer/presentation";
import { EditCustomerSourceComponent } from "@app/app/customer/presentation/dialog/edit-customer-source/edit-customer-source.component";
import { KeyValueOption } from "@shared/models";

@Injectable({providedIn: "root"})
export class MaterialCustomerInteractionService implements CustomerInteractionService {
    constructor(private readonly matDialog: MatDialog) {
    }

    public async editAddress(customerAddress: CustomerAddress): Promise<CustomerAddress> {
        return this.showDialog<CustomerAddress>(EditCustomerAddressComponent, {address: customerAddress}, (r) =>
            r ? new CustomerAddress(r) : null,
        );
    }

    public async editSource(customerSource: CustomerSource, shops: KeyValueOption[]): Promise<CustomerSource> {
        return this.showDialog<CustomerSource>(EditCustomerSourceComponent, {source: customerSource, shops}, (r) =>
            r ? new CustomerSource(r) : null,
        );
    }

    public mergeAddresses(addressesToMerge: CustomerAddress[]): Promise<CustomerAddress> {
        return this.showDialog<CustomerAddress>(
            MergeCustomerAddressesComponent,
            {addressesToMerge},
            (r) => (r ? new CustomerAddress(r) : null),
            {minWidth: "60%"},
        );
    }

    public enterExportSettings(
        currentSettings: CustomerExportSettings,
        fileFormats: FileFormat[],
        showAppliedFilterOption: boolean,
        showSelectedCustomersOption: boolean,
    ): Promise<CustomerExportSettings> {
        return this.showDialog<CustomerExportSettings>(
            ExportCustomersComponent,
            {currentSettings, fileFormats, showAppliedFilterOption, showSelectedCustomersOption},
            (r) => (r ? new CustomerExportSettings(r) : null),
        );
    }

    public enterImportSettings(
        currentSettings: CustomerImportSettings,
        fileFormats: FileFormat[],
    ): Promise<CustomerImportSettings> {
        return this.showDialog<CustomerImportSettings>(ImportCustomersComponent, {currentSettings, fileFormats}, (r) =>
            r ? new CustomerImportSettings(r) : null,
        );
    }

    public mergeCustomers(customersToMerge: Customer[]): Promise<Customer> {
        return this.showDialog<Customer>(
            MergeCustomersComponent,
            {customersToMerge},
            (r) => (r ? new Customer(r) : null),
            {
                width: "80%",
            },
        );
    }

    public showCustomerImportResult(result: ImportedCustomer[]): void {
        this.showDialog<Customer>(ImportResultComponent, {result}, (r) => null, {
            width: "50%",
        });
    }

    public async editMetaData(metaData: CustomerMetaInformation): Promise<CustomerMetaInformation | null> {
        return this.showDialog<CustomerMetaInformation>(EditMetaDataComponent, {metaData}, (r) =>
            r == null ? null : new CustomerMetaInformation(r),
        );
    }

    private showDialog<T>(
        component: ComponentType<any>,
        data: any,
        afterClosed: (r) => T,
        options: MatDialogConfig = null,
    ): Promise<T> {
        return new Promise((resolve) => {
            const dialogRef = this.matDialog.open(component, {
                data,
                disableClose: true,
                ...options,
            });

            dialogRef.afterClosed().subscribe((r) => {
                resolve(afterClosed(r));
            });
        });
    }
}
