import {ChangeDetectionStrategy, Component} from "@angular/core";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {TranslateModule} from "@ngx-translate/core";

@Component({
	templateUrl: "./epages-hint-dialog.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatLegacyDialogModule, MatLegacyButtonModule, TranslateModule],
})
export class EpagesHintDialogComponent {}
