import {Component, Inject} from "@angular/core";
import {ArticleService} from "@app/app/common/service/da/article.service";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FormModel} from "@mintware-de/form-builder";
import {RawText, Switch, TableCollection} from "@app/app/common/form/types";
import {UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {TableRowColumn} from "@app/app/common/form/collection/table-collection/table-row.options";

interface ManualStockSyncFormData {
    Rows: Array<{
        Name: string,
    }>;
}

@Component({
    selector: "bb-manual-stocksync-dialog",
    templateUrl: "./manual-stock-sync-dialog.component.html",
    styles: [],
})
export class ManualStockSyncDialogComponent {

    public isLoaded: boolean = false;

    public formData: ManualStockSyncFormData = {
        Rows: [],
    };

    private details: any[] = [];

    public formModel: FormModel<ManualStockSyncFormData> = {};

    constructor(
        private readonly articleService: ArticleService,
        @Inject(MAT_DIALOG_DATA) public data: { articleIds: number[] },
        private readonly dialog: MatDialogRef<ManualStockSyncDialogComponent>,
        @Inject("$alert")private readonly alert: AlertService,
        @Inject("$translate")private $translate: ng.translate.ITranslateService,
    ) {
        this._loadData(data.articleIds).then();
    }

    private async _loadData(articleIds: number[]): Promise<void> {
        const details = await this.articleService.getDetailsForManualStockSync(articleIds);
        this.details = details;
        this.formData.Rows = this.details.reduce((rows, current) => {
            rows.push({
                Name: current.Name,
                ...current.Sources.reduce((obj, source) => ({
                    ...obj,
                    [source.SourceApiAccountId]: source.IsActive,
                }), {}),
            });
            return rows;
        }, []);

        const mappedSources = [];
        const headings: TableRowColumn<any, any>[] = [];
        for (const detail of details) {
            for (const source of detail.Sources) {
                if (!mappedSources.includes(source.SourceApiAccountId)) {
                    mappedSources.push(source.SourceApiAccountId);
                    headings.push({
                        title: source.SourceName,
                        field: source.SourceApiAccountId,
                        selectAllCallback: (field, checked, group) => {
                            const rows = group.get("Rows") as UntypedFormArray;
                            for (let i = 0; i < rows.controls.length; i++) {
                                const row = rows.controls[i] as UntypedFormGroup;
                                for (const sourceApiAccountId of Object.keys((row as UntypedFormGroup).controls)) {
                                    if (field == sourceApiAccountId && this._hasSource(i, sourceApiAccountId)) {
                                        row.controls[sourceApiAccountId].patchValue(checked);
                                    }
                                }
                            }
                        },
                        content: new Switch({
                            label: "",
                        }),
                    });
                }
            }
        }

        this.formModel = {
            Rows: new TableCollection<ManualStockSyncFormData>({
                noDataText: "text.no_product_selected",
                allowAdd: false,
                allowDelete: false,
                entrySettings: {
                    columns: [
                        {
                            title: "Name",
                            field: "Name",
                            content: new RawText({builder: (data: ManualStockSyncFormData, row) => data.Rows[row.mwIndex].Name}),
                        },
                        ...headings,
                    ],
                },
                showCellCallback: (rowIndex: number, cellField: string) =>
                    cellField === "Name" || this._hasSource(rowIndex, cellField),
            }),
        };

        this.isLoaded = true;
    }

    private _hasSource(rowIndex: number, sourceApiAccountId: string): boolean {
        return this.details[rowIndex].Sources.find(s => s.SourceApiAccountId == sourceApiAccountId) != null;
    }

    public submit($event: ManualStockSyncFormData): void {
        const sourcesToSync = [];
        for (let index = 0; index < $event.Rows.length; index++) {
            const selectedSources = [];
            const detailFromServer = this.details[index];
            for (const source of detailFromServer.Sources) {
                if ($event.Rows[index][source.SourceApiAccountId] === true) {
                    selectedSources.push(source.Id);
                }
            }
            if (selectedSources) {
                sourcesToSync.push(...selectedSources);
            }
        }

        if (sourcesToSync.length === 0) {
            return;
        }

        this.alert.confirm(
            this.$translate.instant("Soll der Bestand für die ausgewählten Artikel und Shops wirklich übertragen werden?"),
            this.$translate.instant("Bestand übertragen?"), () => {
                this.articleService.startManualStockSync(sourcesToSync).then();
                this.alert.success(
                    this.$translate.instant("Bestandsabgleich gestartet"),
                    this.$translate.instant("Der aktuelle Bestand wird für die ausgewählten Artikel übertragen"));
            }, () => {
                this.dialog.close();
            },
        );

        this.dialog.close();
    }
}
