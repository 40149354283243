import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { CustomerMetaInformation } from "@app/app/customer/core";
import { EditMetaDataViewModel } from "@app/app/customer/presentation/dialog/edit-meta-data/edit-meta-data.view-model";

@Component({
    selector: "bb-customer-edit-meta-data",
    template: `
        <h4 mat-dialog-title>{{ (viewModel.metaData.Type == 1 ? 'title.edit_mail_address' : 'title.edit_phone_number') | translate }}</h4>
        <mat-dialog-content>
            <mw-form-builder #form
                             [mwFormModel]="viewModel.formModel"
                             [mwFormData]="viewModel.metaData"
                             (mwFormSubmit)="onSubmit($event)">

            </mw-form-builder>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button type="button" mat-button [mat-dialog-close]="null">{{ 'button.cancel' | translate }}</button>
            <button type="button" mat-raised-button color="primary" (click)="form.submit()">
                {{ 'button.save' | translate }}
            </button>
        </mat-dialog-actions>
    `,
})
export class EditMetaDataComponent {
    public readonly viewModel: EditMetaDataViewModel = new EditMetaDataViewModel();

    constructor(@Inject(MAT_DIALOG_DATA) data: { metaData: CustomerMetaInformation },
                private readonly dialogRef: MatDialogRef<EditMetaDataComponent>) {
        this.viewModel.metaData = data.metaData;
    }

    public onSubmit($event: CustomerMetaInformation): void {
        this.dialogRef.close(new CustomerMetaInformation($event));
    }
}
