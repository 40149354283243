import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyDialogModule, MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {MatLegacyFormFieldModule} from "@angular/material/legacy-form-field";
import {TranslateModule} from "@ngx-translate/core";
import {BbInputComponent} from "../../../../../shared/ui/form/bb-input/bb-input.component";

@Component({
    selector: "bb-kaufland-api-key",
    templateUrl: "./kaufland-api-key-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatLegacyDialogModule,
        TranslateModule,
        BbInputComponent,
        MatLegacyButtonModule,
        ReactiveFormsModule,
        MatLegacyFormFieldModule,
    ],
})
export class KauflandApiKeyDialogComponent {
    public readonly form = new FormGroup({
        kauflandApiKey: new FormControl("", Validators.required),
        kauflandApiSecret: new FormControl("", Validators.required),
    });

    private readonly dialogRef = inject(MatLegacyDialogRef);

    public continue(): void {
        if (this.form.invalid) {
            return;
        }

        this.dialogRef.close(this.form.value);
    }
}
