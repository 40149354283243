import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Address, RecipientDetails, RecipientOptions } from "../../core/entity";
import * as coreSvc from "../../core/service";
import { EditAddressDialogComponent } from "../../presentation/edit-address-dialog/edit-address-dialog.component";
import { PickAddressDialogComponent } from "../../presentation/pick-address-dialog/pick-address-dialog.component";


@Injectable({providedIn: "root"})
export class ShippingInteractionService implements coreSvc.ShippingInteractionService {

    constructor(private readonly dialog: MatDialog) {
    }

    public editRecipientDetails(recipientDetails: RecipientDetails,
                                options: RecipientOptions,
    ): Promise<RecipientDetails> {
        return new Promise((resolve) => {
            const dialog = this.dialog.open(EditAddressDialogComponent, {
                data: {recipientDetails, options},
            });
            dialog.afterClosed().subscribe((res) => {
                resolve(res);
            });
        });
    }

    public pickAddress(customerId: number,
                       addresses: Address[],
                       orderId: number,
                       currentAddressId?: number): Promise<Address> {
        return new Promise((resolve) => {
            const dialog = this.dialog.open(PickAddressDialogComponent, {
                data: {customerId, addresses, currentAddressId, orderId},
                width: "100%",
                maxWidth: "500px",
            });
            dialog.afterClosed().subscribe((res) => {
                resolve(res);
            });
        });
    }
}
