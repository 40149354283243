<div fxFill fxLayout="column" *ngIf="options">
    <dx-data-grid #grid
                  [hidden]="options.HideWhenNoResults && !(dataSource.isBusy$ | async) && (dataSource.totalCount$ | async) == 0"
                  [dataSource]="dataSource"
                  [remoteOperations]="true"
                  [allowColumnReordering]="true"
                  [filterSyncEnabled]="true"
                  [repaintChangesOnly]="true"
                  [renderAsync]="true"
                  fxFlex
                  height="85vh"
                  [rowAlternationEnabled]="true"
                  [showBorders]="true"
                  [columnResizingMode]="'widget'"
                  [columnMinWidth]="100"
                  [allowColumnResizing]="options.AllowColumnResizing"
                  (onSelectionChanged)="onSelectionChanged()"
                  [(selectedRowKeys)]="selectedKeys"
                  (onOptionChanged)="onOptionChanged($event)"
                  style="margin: 8px 0; margin-right: 4px">

        <dxo-scrolling mode="virtual" showScrollbar="always" rowRenderingMode="virtual"
                       columnRenderingMode="virtual"></dxo-scrolling>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-paging [pageSize]="50"></dxo-paging>
        <dxo-editing
                mode="cell"
                refreshMode="repaint"
                [allowDeleting]="true"
                [allowUpdating]="true">
        </dxo-editing>
        <dxo-selection *ngIf="options.SelectionMode != 'none'"
                       [allowSelectAll]="false"
                       showCheckBoxesMode="always"
                       [mode]="options.SelectionMode">
        </dxo-selection>

        <dxo-filter-row [visible]="options.ShowHeaderFilter"></dxo-filter-row>
        <dxi-column type="buttons">
            <dxi-button
                    icon="edit"
                    [visible]="options.ShowEditButton"
                    [onClick]="clickEdit"
            ></dxi-button>
        </dxi-column>
        <ng-container *ngFor="let col of options.Columns; trackBy:colName">
            <dxi-column [dataField]="col.ValueTransformer == null ? col.Name : null"
                        [dataType]="col.Type == 'decimal' ? 'number' : col.Type"
                        [visible]="col.IsVisible"
                        [allowSorting]="col.AllowSorting"
                        [allowFiltering]="options.ShowHeaderFilter && col.AllowFiltering"
                        [allowEditing]="options.ShowEditButton && col.AllowEditing"
                        [caption]="(col.Caption ?? col.Name) | translate"
                        [calculateCellValue]="col.ValueTransformer"
                        [calculateDisplayValue]="col.DisplayTransformer"
                        [cellTemplate]="col.DisplayTransformer != null ? col.CellTemplateName : null"
                        [allowHeaderFiltering]="options.ShowHeaderFilter && col.AllowFiltering"
            >
                <dxo-lookup *ngIf="col.LookupValues" [dataSource]="col.LookupValues" displayExpr="text"
                            valueExpr="value"></dxo-lookup>

                <dxo-format *ngIf="col.Type == 'decimal'" type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
        </ng-container>

        <ng-container *dxTemplate="let d of 'htmlCellTemplate'">
            <span [innerHtml]="d.text | safeHtml"></span>
        </ng-container>
        <ng-container *dxTemplate="let d of 'arrayCellTemplate'">
            <ul>
                <li *ngFor="let item of d.displayValue">{{item | translate}}</li>
            </ul>
        </ng-container>
    </dx-data-grid>
</div>
<bb-no-data-container
        *ngIf="!(dataSource.isBusy$ | async) && (dataSource.totalCount$ | async) == 0"
        [noDataText]="options.NoDataText">
</bb-no-data-container>