import {Component, Inject} from "@angular/core";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {ImportedCustomer} from "@app/app/customer/core";

@Component({
    selector: "bb-import-result-dialog",
    template: `
        <h4 mat-dialog-title>{{ 'title.imported_customers' | translate }}</h4>
        <mat-dialog-content>
            <table class="material">
                <thead>
                <tr>
                    <th>{{ 'label.customer_number' | translate }}</th>
                    <th>{{ 'label.name' | translate }}</th>
                    <th>{{ 'title.result' | translate }}</th>
                    <th>{{ 'title.comments' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of result" [ngClass]="{'text-error': item.Result == 2}">
                    <td>{{ item.Number }}</td>
                    <td>{{ item.CustomerName }}</td>
                    <td>{{ (item.Result == 0 ? 'label.created' : item.Result == 1 ? 'label.updated' : 'label.error') | translate  }}</td>
                    <td>{{ item.ResultText }}</td>
                </tr>
                </tbody>
            </table>
        </mat-dialog-content>
        <mat-dialog-actions fxLayoutAlign="end">
            <button type="button" mat-button [mat-dialog-close]="null">{{ 'button.close' | translate }}</button>
        </mat-dialog-actions>
    `,
})
export class ImportResultComponent {
    public result: ImportedCustomer[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) private readonly data: any) {
        this.result = data.result;
    }
}
