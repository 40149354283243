<div fxLayout="column" fxLayoutGap="16px">
    <mat-card *ngIf="viewModel.customer && viewModel.customer.ArchiveState == 2" color="warn">
        <mat-card-content>
            <mat-icon fontIcon="fa-hourglass-half"></mat-icon>
            {{ "text.customer.restore_is_requested" | translate }}
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="viewModel.customer && viewModel.customer.ArchiveState == 1" color="warn">
        <mat-card-content>
            <mat-icon fontIcon="fa-lock"></mat-icon>
            {{ "text.customer.can_not_save_archived_customer" | translate }}
        </mat-card-content>
    </mat-card>
    <mw-form-builder *ngIf="viewModel.customer"
                     #customerForm
                     [mwFormData]="viewModel.customer"
                     [mwFormModel]="viewModel.formModel"
                     (mwFormSubmit)="onSubmit($event)">
    </mw-form-builder>
</div>