import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {TranslateModule} from "@ngx-translate/core";
import {OnboardingAssistantCountryTaskComponent} from "../tasks/country/country.component";
import {OnboardingAssistantWelcomeTaskComponent} from "../tasks/welcome/welcome.component";
import {ONBOARDING_ASSISTANT_STEPS} from "./steps.token";

const STEP_TASK_COMPONENTS = [OnboardingAssistantWelcomeTaskComponent, OnboardingAssistantCountryTaskComponent];

@NgModule({
    imports: [CommonModule, TranslateModule, MatButtonModule, MatMenuModule],
    declarations: STEP_TASK_COMPONENTS,
    providers: [
        {
            provide: ONBOARDING_ASSISTANT_STEPS,
            useValue: {
                title: "title.core_data",
                subtitle: "text.core_data_description",
                tasks: STEP_TASK_COMPONENTS,
            },
            multi: true,
        },
    ],
})
export class OnboardingAssistantCoreDataStepModule {}
