import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {TranslateModule} from "@ngx-translate/core";
import {OnboardingAssistantDialogComponent} from "./container/onboarding-assistant-dialog/onboarding-assistant-dialog.component";
import {OnboardingAssistantAddChannelsStepModule} from "./steps/add-channels.module";
import {OnboardingAssistantCoreDataStepModule} from "./steps/core-data.module";
import {OnboardingAssistantFinishStepModule} from "./steps/finish.module";
import {OnboardingAssistantTaxDataStepModule} from "./steps/tax-data.module";

const STEP_MODULES = [
    OnboardingAssistantCoreDataStepModule,
    OnboardingAssistantTaxDataStepModule,
	OnboardingAssistantAddChannelsStepModule,
	OnboardingAssistantFinishStepModule,
];

@NgModule({
    imports: [
        ...STEP_MODULES,
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
    ],
	declarations: [OnboardingAssistantDialogComponent],
})
export class OnboardingAssistantModule {}
