import {Component, Inject} from "@angular/core";
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from "@angular/material/legacy-snack-bar";

@Component({
    selector: "bb-snackbar-container",
    template: `<div [innerHtml]="data"></div>`,
})
export class SnackbarContainerComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { }
}
