import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyDialogModule, MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {MatLegacyFormFieldModule} from "@angular/material/legacy-form-field";
import {TranslateModule} from "@ngx-translate/core";
import {BbSelectComponent} from "../../../../../shared/ui/form/bb-select/bb-select.component";

@Component({
    selector: "bb-amazon-shop-choose-region",
    templateUrl: "./choose-region-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatLegacyDialogModule,
        TranslateModule,
        BbSelectComponent,
        MatLegacyButtonModule,
        ReactiveFormsModule,
        MatLegacyFormFieldModule,
    ],
})
export class AmazonShopChooseRegionDialogComponent {
    public readonly regionFormControl = new FormControl("", Validators.required);
    public readonly options: KeyValuePair<string, string>[] = [
        {Key: "area_eu", Value: "EU"},
        {Key: "area_na", Value: "NA"},
        {Key: "area_fe", Value: "FE"},
	];
	
	private readonly dialogRef = inject(MatLegacyDialogRef);

    public chooseRegion(): void {
        if (this.regionFormControl.invalid) {
            return;
        }

        this.dialogRef.close(this.regionFormControl.value);
    }
}
