import {AbstractFormFieldComponent, AbstractType} from "@mintware-de/form-builder";
import {TagsFieldOptions} from "./tags-field.options";
import {AfterViewInit, Component} from "@angular/core";
import {MatLegacyChipInputEvent as MatChipInputEvent} from "@angular/material/legacy-chips";
import {COMMA, ENTER, TAB} from "@angular/cdk/keycodes";

@Component({
    selector: "bb-form-tags-field",
    template: `
        <ng-container>
            <mat-form-field [matTooltip]="mwFieldType.options.tooltip | translate"
                            [hidden]="!mwFieldType.options.visible">
                <mat-label>{{ mwFieldType.options.label | translateLabel }}</mat-label>
                <mat-chip-list #chipList [selectable]="false">
                    <mat-chip *ngFor="let tag of tags"
                              [removable]="!mwFieldType.options.readonly"
                              (removed)="remove(tag)">
                        {{tag}}
                        <mat-icon matChipRemove [inline]="true" *ngIf="!mwFieldType.options.readonly"
                                  fontIcon="fa-times"></mat-icon>
                    </mat-chip>
                    <input *ngIf="!mwFieldType.options.readonly"
                           [matChipInputFor]="chipList"
                           [disabled]="mwFieldType.options.readonly"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="false"
                           (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>

            </mat-form-field>
        </ng-container>
    `,
})
export class TagsFieldComponent extends AbstractFormFieldComponent<AbstractType<TagsFieldOptions>> implements AfterViewInit {
    public readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];

    public tags: string[] = [];

    public remove(tag: string): void {
        this.tags.splice(this.tags.indexOf(tag), 1);
        this.mwElement.patchValue(this.tags);
    }

    public add($event: MatChipInputEvent): void {
        const tag = $event.value;
        if (tag && this.tags.indexOf(tag) === -1) {
            this.tags.push(tag);
            $event.input.value = "";
            this.mwElement.patchValue(this.tags);
        }
    }

    public ngAfterViewInit(): void {
        this.mwElement.valueChanges.subscribe((value) => {
            if (!Array.isArray(value)) {
                this.tags = [];
                return;
            }
            this.tags = Array.from(new Set([...value]));
        });
    }

}
