import {Component, Inject} from "@angular/core";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {CustomerSource} from "@app/app/customer/core";
import {KeyValueOption} from "@shared/models";
import {EditCustomerSourceViewModel} from "./edit-customer-Source.view-model";

@Component({
    selector: "bb-edit-customer-source-dialog",
    template: `
        <h4 mat-dialog-title>{{ (viewModel.source.Id == 0 ? 'title.add_source' : 'title.edit_source') | translate }}</h4>
        <mat-dialog-content>
            <mw-form-builder #form
                             [mwFormModel]="viewModel.formModel"
                             [mwFormData]="viewModel.source"
                             (mwFormSubmit)="submit($event)">
            </mw-form-builder>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button type="button" mat-button [mat-dialog-close]="null">{{ 'button.cancel' | translate }}</button>
            <button type="button" mat-raised-button color="primary" (click)="form.submit()">
                {{ 'button.save' | translate }}
            </button>
        </mat-dialog-actions>
    `,
})
export class EditCustomerSourceComponent {
    public readonly viewModel: EditCustomerSourceViewModel = new EditCustomerSourceViewModel();

    public constructor(@Inject(MAT_DIALOG_DATA) private readonly data: { source: CustomerSource, shops: KeyValueOption[] },
                       private readonly dialogRef: MatDialogRef<EditCustomerSourceComponent>,
    ) {
        const customerSource = new CustomerSource(data.source);
        if (customerSource.ApiAccountId) {
            customerSource.Source = `${customerSource.Source}#${customerSource.ApiAccountName}#${customerSource.ApiAccountId}`;
        }
        this.viewModel.source = customerSource;
        const options = data.shops.map(x => ({text: x.label, value: x.value?.toString()}));
        options.push(...[
            // {value: "DatevDebitor", text: "label.datev_debitor_number"},
        ]);
        this.viewModel.selectField.reportChanges(options);
    }

    public submit(source: CustomerSource): void {
        const newSource = new CustomerSource(Object.assign({}, this.viewModel.source, source));
        newSource.ApiAccountName = null;
        newSource.ApiAccountId = null;
        if (newSource.Source.includes("#")) {
            const [partner, name, id] = newSource.Source.split("#", 3);
            newSource.Source = partner;
            newSource.ApiAccountName = name;
            newSource.ApiAccountId = Number(id);
        }
        this.dialogRef.close(newSource);
    }
}
