import {AbstractCollectionComponent} from "@mintware-de/form-builder";
import {Component, OnInit} from "@angular/core";
import {SimpleCollectionOptions} from "./simple-collection.options";
import {InteractionService, TranslationService} from "@bb-core/service";

@Component({
    selector: "bb-form-simple-collection",
    template: `
        <div class="mat-subheading-2" style="margin-top: 8px; margin-bottom: 0"
             *ngIf="mwFieldType.options.title">{{ mwFieldType.options.title | translate }}</div>
        <div fxLayout="column">
            <i *ngIf="(!mwElement.controls || !mwElement.controls.length)  && mwFieldType.options.noDataText">{{ mwFieldType.options.noDataText | translate }}</i>
            <div *ngFor="let child of mwElement.controls; let i=index" fxLayout="row" fxLayoutGap="8px">
                <div fxFlex>
                    <ng-container mwFormField
                                  [mwFormGroup]="mwFormGroup"
                                  [mwElement]="child"
                                  [mwFieldType]="mwFieldType.fieldInstance"
                                  [mwPath]="mwPath + '_'+ i"
                                  [mwIndex]="i"></ng-container>
                </div>
                <div fxFlex="none">
                    <button *ngIf="mwFieldType.options.allowDelete"
                            type="button"
                            (click)="removeEntry(i)"
                            mat-icon-button>
                        <mat-icon fontIcon="fa-trash"></mat-icon>
                    </button>
                </div>
            </div>
            <div fxLayout="row">
                <button *ngIf="mwFieldType.options.showAddButton"
                        type="button"
                        fxFlex="none"
                        mat-icon-button
                        (click)="addEntry()"
                        class="mat-elevation-z1">
                    <mat-icon fontIcon="fa-plus"></mat-icon>
                </button>
            </div>
            <div *ngIf="mwElement && mwElement.errors">
                <mat-error *ngIf="!mwFieldType.options.validationMessages.required"
                           [hidden]="!mwElement.errors.required || !mwElement.parent.touched">
                    {{ 'error.element_required' | translate }}
                </mat-error>
                <mat-error *ngFor="let kvp of mwFieldType.options.validationMessages | keyvalue:trueCompare"
                           [hidden]="!mwElement.errors[kvp.key] || !mwElement.parent.touched">
                    {{ kvp.value | translate }}
                </mat-error>
            </div>
        </div>
    `,
})
export class SimpleCollectionComponent extends AbstractCollectionComponent<any, SimpleCollectionOptions<any>> implements OnInit {


    constructor(private readonly translator: TranslationService,
                private readonly interaction: InteractionService,
    ) {
        super();
    }

    public ngOnInit(): void {
        (this.mwFieldType as any).componentInstance = this;
        let wasInitialized = false;
        this.mwElement.valueChanges.subscribe((valueChange) => {
            if (wasInitialized) {
                return;
            }
            wasInitialized = true;
            if (this.mwFieldType.options.afterInit != null) {
                this.mwFieldType.options.afterInit();
            }
        });
    }
    
    public addEntry(): void {
        super.addEntry();
        this.mwElement.markAsDirty();
        this.mwElement.markAsTouched();
        this.mwElement.markAllAsTouched();
    }

    public removeEntry(index: number): void {
        (new Promise<void>(async (resolve) => {
            if (await this.confirmDelete()) {
                super.removeEntry(index);
                this.mwElement.markAsTouched();
                this.mwElement.markAllAsTouched();
            }
            resolve();
        })).then();
    }

    public trueCompare(_: any, __: any): number {
        return 0;
    }

    private confirmDelete(): Promise<boolean> {
        const title = this.translator.translate("title.confirm_delete_record");
        const message = this.translator.translate("text.confirm_delete_record");
        return this.interaction.confirm(title, message);
    }
}
