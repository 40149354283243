import {ChangeDetectionStrategy, Component, Inject} from "@angular/core";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {TranslateModule} from "@ngx-translate/core";

interface ShopLoadingDialogData {
	logo: string;
	title: string;
	text: string;
}

@Component({
    selector: "bb-shop-loading-dialog",
    templateUrl: "./shop-loading-dialog.component.html",
    styleUrls: ["./shop-loading-dialog.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatLegacyDialogModule, TranslateModule, MatLegacyProgressSpinnerModule, MatLegacyButtonModule],
})
export class ShopLoadingDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ShopLoadingDialogData) {}
}
